import { useState } from "react";
import { Table, Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const generatePaginationItems = (currentPage, totalPages, onPageChange) => {
    const paginationItems = [];
    const maxVisiblePages = 5; // Number of visible pages around the current page

    const addEllipses = (key) => {
      paginationItems.push(<Pagination.Ellipsis key={key} />);
    };

    // Add first 5 pages
    for (let i = 1; i <= Math.min(5, totalPages); i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    // Add ellipses if needed
    if (currentPage > 5) {
      addEllipses("ellipsis-start");
    }

    // Add pages around the current page
    const startPage = Math.max(6, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages - 5, currentPage + Math.floor(maxVisiblePages / 2));

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    // Add ellipses if needed
    if (currentPage < totalPages - 5) {
      addEllipses("ellipsis-end");
    }

    // Add last 5 pages
    for (let i = Math.max(totalPages - 4, 6); i <= totalPages; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="justify-content-end">
      <Pagination.Prev onClick={() => currentPage > 1 && onPageChange(currentPage - 1)} disabled={currentPage === 1} />
      {generatePaginationItems(currentPage, totalPages, onPageChange)}
      <Pagination.Next
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

const PaginationTable = ({ data, headerRow, recordRowMap, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the paginated items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      <Table responsive bordered striped hover size="sm">
        <thead>{headerRow}</thead>
        <tbody>
          {currentData.map((item, index) =>
            recordRowMap(item, index, { offsetNumber: (currentPage - 1) * itemsPerPage }),
          )}
        </tbody>
      </Table>
      <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default PaginationTable;
