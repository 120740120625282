import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getMergeSlikList,
  getMergeSlik,
  getSlikList,
  getMergeSlikPartnerLogList,
  getSlikChart,
  getAutoCompletePartnerList,
  getAutoCompletePartnerCompanyList,
} from "utils/api";
import {
  transformSnakeToCamelInArray,
  transformSnakeToCamelObject,
  transformChartToMultiDatasets,
  chartColors,
  getBoSettingsValue,
  updateBoSettings,
} from "utils/helper";
import { useNotification } from "contexts/notification";
import { useSocket } from "contexts/socket";

const paginationListDefault = {
  data: [],
  meta: {
    totalPage: 0,
    totalData: 0,
    totalDataPerPage: 0,
    currentPage: 0,
  },
};

const responseDefault = {
  type: null,
  status: false,
  message: null,
  data: null,
};

const simpleListDefault = {
  data: [],
  meta: {
    totalData: 0,
  },
  default: true,
};

const chartDefault = {
  labels: [],
  datasets: [],
};

const EXECUTION_INTERVAL_TIME = 3 * 1000;
const RESET_NEW_UUIDS_DEBOUNCE_TIME = 10 * 1000;
const RESET_NEW_ROWSTYLE_DEBOUNCE_TIME = 30 * 1000;

export const SlikContext = createContext(null);

export const useSlik = () => {
  const ctx = useContext(SlikContext);

  if (!ctx) {
    throw new Error("useSlik must be used within the SlikProvider");
  }

  return ctx;
};

const SlikProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pushNotification } = useNotification();
  const { subscribe, unsubscribe, events } = useSocket();

  const autoUpdateSettings = useRef({ uuids: [] });
  const [isAutoUpdateList, setIsAutoUpdateList] = useState(getBoSettingsValue("mergeSlikAutoUpdate") || false);
  const [mergeSlikList, setMergeSlikList] = useState(paginationListDefault);
  const [mergeSlikListParams, setMergeSlikListParams] = useState({});
  const [mergeSlik, setMergeSlik] = useState(null);
  const [mergeSlikPartnerLogList, setMergeSlikPartnerLogList] = useState(simpleListDefault);
  const [slikList, setSlikList] = useState(paginationListDefault);
  const [slikDailyChart, setSlikDailyChart] = useState(chartDefault);
  const [slikMonthlyChart, setSlikMonthlyChart] = useState(chartDefault);
  const [slikYearlyChart, setSlikYearlyChart] = useState(chartDefault);
  const [slikMonthlyPartnerCharts, setSlikMonthlyPartnerCharts] = useState([]);
  const [slikYearlyPartnerCharts, setSlikYearlyPartnerCharts] = useState([]);
  const [response, setResponse] = useState(responseDefault);
  const [chartLoading, setChartLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoCompletePartnerList, setAutoCompletePartnerList] = useState(simpleListDefault);
  const [autoCompletePartnerCompanyList, setAutoCompletePartnerCompanyList] = useState(simpleListDefault);

  const handleAutoUpdateList = (event) => {
    updateBoSettings("mergeSlikAutoUpdate", event.target.checked);
    setIsAutoUpdateList(event.target.checked);
  };

  const handleGetMergeSlikList = async (params) => {
    const currentSettingsIsAutoUpdateList = autoUpdateSettings.current.isAutoUpdateList;
    const currentSettingsUuids = autoUpdateSettings.current.uuids || [];

    // Cancels resetting new uuids.
    if (autoUpdateSettings.current.resetNewUuidsTimeoutId) {
      clearTimeout(autoUpdateSettings.current.resetNewUuidsTimeoutId);
      autoUpdateSettings.current.resetNewUuidsTimeoutId = null;
    }

    // Resets new uuids.
    autoUpdateSettings.current.resetNewUuidsTimeoutId = setTimeout(() => {
      autoUpdateSettings.current.uuids = [];
    }, RESET_NEW_UUIDS_DEBOUNCE_TIME);

    setMergeSlikListParams(params);

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlikList(params);

      if (res.status === 200) {
        const resetRowStyleMergeSlikList = () => {
          setMergeSlikList((prev) => ({
            ...prev,
            data: prev.data.map((x) => {
              const item = { ...x };
              delete item.rowStyle;
              return item;
            }),
            meta: {
              ...prev.meta,
              totalPage: res.data.meta.total_page,
              totalData: res.data.meta.total_data_all,
              totalDataPerPage: res.data.meta.total_data || params.limit,
              currentPage: params.page,
            },
          }));
        };
        const setupRowStyleMergeSlikList = (formattedList1, newUuids) => {
          setMergeSlikList((prev) => {
            const oldList = prev.data || [];
            const newItems = formattedList1.filter((newItem) => {
              if (newUuids.includes(newItem.uuid)) return true;

              const foundItem = oldList.find((oldItem) => oldItem.uuid === newItem.uuid);
              if (!foundItem) return false;
              if (newUuids.includes(foundItem.uuid)) return true;
              return false;
            });
            newItems.forEach((newItem) => (newItem.rowStyle = "table-success"));
            const newList = {
              ...prev,
              data: formattedList1,
              meta: {
                ...prev.meta,
                totalPage: res.data.meta.total_page,
                totalData: res.data.meta.total_data_all,
                totalDataPerPage: res.data.meta.total_data || params.limit,
                currentPage: params.page,
              },
            };
            return newList;
          });
        };

        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        if (currentSettingsIsAutoUpdateList) {
          // Sets new item flags.
          setupRowStyleMergeSlikList(transformSnakeToCamelInArray(res.data.data), currentSettingsUuids);

          // Cancels resetting row style.
          if (autoUpdateSettings.current.resetNewRowStyleTimeoutId) {
            clearTimeout(autoUpdateSettings.current.resetNewRowStyleTimeoutId);
            autoUpdateSettings.current.resetNewRowStyleTimeoutId = null;
          }

          // Resets row style.
          autoUpdateSettings.current.resetNewRowStyleTimeoutId = setTimeout(
            resetRowStyleMergeSlikList,
            RESET_NEW_ROWSTYLE_DEBOUNCE_TIME,
          );
        } else {
          setMergeSlikList((prev) => ({
            ...prev,
            data: formattedList,
            meta: {
              ...prev.meta,
              totalPage: res.data.meta.total_page,
              totalData: res.data.meta.total_data_all,
              totalDataPerPage: res.data.meta.total_data || params.limit,
              currentPage: params.page,
            },
          }));
        }

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlegetAutoCompletePartnerList = async (params = { search: "", limit: "", uuid: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_LIST",
    }));

    // api call
    try {
      const res = await getAutoCompletePartnerList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setAutoCompletePartnerList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handlegetAutoCompletePartnerCompanyList = async (params = { search: "", limit: "" }) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "PARTNER_COMPANY_LIST",
    }));

    // api call
    try {
      const res = await getAutoCompletePartnerCompanyList(params);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setAutoCompletePartnerCompanyList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
          },
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeSlik = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlik(id);

      if (res.status === 200) {
        const temp = transformSnakeToCamelObject(res.data.data);

        setMergeSlik((prev) => ({
          ...prev,
          ...temp,
        }));

        setResponse((prev) => ({
          ...prev,
          data: temp,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetMergeSlikPartnerLogList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "MERGE_SLIK_PARTNER_LOG_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getMergeSlikPartnerLogList(id);

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setMergeSlikPartnerLogList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev?.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
          default: false,
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetSlikList = async (id) => {
    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_LIST",
    }));
    setLoading(true);

    // api call
    try {
      const res = await getSlikList({ merge_uuid: id });

      if (res.status === 200) {
        const formattedList = transformSnakeToCamelInArray(res.data.data);

        // set state
        setSlikList((prev) => ({
          ...prev,
          data: formattedList,
          meta: {
            ...prev.meta,
            totalPage: res.data.meta.total_page,
            totalData: res.data.meta.total_data_all,
          },
        }));

        setResponse((prev) => ({
          ...prev,
          data: formattedList,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  const handleGetSlikChart = async (type) => {
    if (!type) return;

    let isSuccess = false;
    let message = null;

    setResponse((prev) => ({
      ...prev,
      ...responseDefault,
      type: "SLIK_CHART_" + type.toUpperCase(),
    }));
    setChartLoading(true);

    // api call
    try {
      const res = await getSlikChart({ type });
      if (res.status === 200) {
        // set state
        if (type.endsWith("_partner")) {
          let fn;
          if (type === "yearly_partner") {
            fn = setSlikYearlyPartnerCharts;
          } else if (type === "monthly_partner") {
            fn = setSlikMonthlyPartnerCharts;
          }

          if (fn) {
            const datasets = transformChartToMultiDatasets(res.data.data, "partner_name", "processed_usage_count");
            fn(datasets);
          }
        } else {
          let fn;
          if (type === "yearly") {
            fn = setSlikYearlyChart;
          } else if (type === "monthly") {
            fn = setSlikMonthlyChart;
          } else if (type === "daily") {
            fn = setSlikDailyChart;
          }

          if (fn) {
            const labels = res.data.data.map((x) => x.period_label);
            const allCounts = res.data.data.map((x) => x.all_count);
            const processedUsageCounts = res.data.data.map((x) => x.processed_usage_count);

            fn((prev) => ({
              ...prev,
              labels: labels,
              datasets: [
                {
                  label: "UPLOADED",
                  data: allCounts,
                  backgroundColor: chartColors.blue,
                },
                {
                  label: "PROCESSED",
                  data: processedUsageCounts,
                  backgroundColor: chartColors.green,
                },
              ],
            }));
          }
        }

        setResponse((prev) => ({
          ...prev,
          data: res.data.data,
        }));

        isSuccess = true;
      }
    } catch (e) {
      pushNotification("error", null, e);
    }

    setChartLoading(false);
    setResponse((prev) => ({
      ...prev,
      status: isSuccess,
      message,
    }));

    return isSuccess;
  };

  useEffect(() => {
    // Not sure needed or not to have local variables.
    const currentSearchParams = searchParams;
    const currentIsAutoUpdateList = isAutoUpdateList;
    const currentMergeSlikListParams = mergeSlikListParams;
    const currentLocationPathname = location.pathname;

    const notificationNewSubmissionHandler = async (data) => {
      if (!currentIsAutoUpdateList) return;
      if (data?.doc_type !== "slik") return;
      if (!currentLocationPathname?.startsWith("/slik-dashboard")) return;

      // Sets ref.
      if (!autoUpdateSettings.current.uuids) autoUpdateSettings.current.uuids = [];
      if (data?.uuid && !autoUpdateSettings.current.uuids.includes(data.uuid)) {
        autoUpdateSettings.current.uuids.push(data.uuid);
      }

      autoUpdateSettings.current.isAutoUpdateList = isAutoUpdateList;

      const execute = async (qp, lp) => {
        autoUpdateSettings.current.executionTimeoutId = null;

        // Redirects to page 1.
        const queryParams = new URLSearchParams(qp);
        const page = queryParams.get("page");
        if (page && page !== "1") {
          queryParams.set("page", 1);
          navigate(`?${queryParams.toString()}`);
          return;
        }

        // Directly calls the same params.
        await handleGetMergeSlikList(lp);
      };

      if (!autoUpdateSettings.current.executionTimeoutId) {
        // Starts a delay execution.
        const qp = currentSearchParams;
        const lp = currentMergeSlikListParams;
        autoUpdateSettings.current.executionTimeoutId = setTimeout(execute, EXECUTION_INTERVAL_TIME, qp, lp);
      }
    };

    subscribe(events.NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
    return () => {
      unsubscribe(events.NOTIFICATION_NEW_SUBMISSION_EVENT, notificationNewSubmissionHandler);
    };
  }, [searchParams, isAutoUpdateList, mergeSlikListParams, location.pathname]);

  useEffect(() => {
    return () => {
      if (autoUpdateSettings.current.executionTimeoutId) {
        clearTimeout(autoUpdateSettings.current.executionTimeoutId);
        autoUpdateSettings.current.executionTimeoutId = null;
      }
    };
  }, []);

  return (
    <SlikContext.Provider
      value={{
        isAutoUpdateList,
        loading,
        chartLoading,
        response,
        mergeSlikList,
        mergeSlik,
        mergeSlikPartnerLogList,
        slikDailyChart,
        slikMonthlyChart,
        slikYearlyChart,
        slikMonthlyPartnerCharts,
        slikYearlyPartnerCharts,
        slikList,
        autoCompletePartnerList: autoCompletePartnerList,
        autoCompletePartnerCompanyList: autoCompletePartnerCompanyList,
        handleAutoUpdateList,
        getMergeSlikList: handleGetMergeSlikList,
        getMergeSlik: handleGetMergeSlik,
        getMergeSlikPartnerLogList: handleGetMergeSlikPartnerLogList,
        getSlikChart: handleGetSlikChart,
        getSlikList: handleGetSlikList,
        getAutoCompletePartnerList: handlegetAutoCompletePartnerList,
        getAutoCompletePartnerCompanyList: handlegetAutoCompletePartnerCompanyList,
      }}
    >
      {children}
    </SlikContext.Provider>
  );
};

export default SlikProvider;
