import { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Skeleton from "react-loading-skeleton";
import { Badge, ButtonGroup, Col, Row } from "react-bootstrap";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TimerClock from "components/Statement/TimerClock";
import StatementSendEmailButton from "components/Statement/StatementSendEmailButton";
import MergeStatementActionButtonGroup from "components/Statement/MergeStatementActionButtonGroup";
import { useStatement } from "contexts/statement";
import { transformSnakeToText } from "utils/helper";
import StatementSendCallbackButton from "components/Statement/StatementSendCallbackButton";

const Info = () => {
  const { id } = useParams();
  const statementActionButtonGroupRef = useRef();
  const {
    loading,
    getMergeStatement,
    mergeStatement,
    getMergeStatementFineTuningLogList,
    mergeStatementFineTuningLogList,
    postMergeStatementStartTuning,
    postMergeStatementExtendTuning,
    postMergeStatementMerge,
    postMergeStatementMarkAsGood,
    postMergeStatementMarkAsError,
    postMergeStatementSendEmail,
    postMergeStatementCallbackPartner,
  } = useStatement();

  const refreshData = async () => {
    await getMergeStatement(id);
    if (!mergeStatementFineTuningLogList?.default) {
      getMergeStatementFineTuningLogList(id);
    }
  };

  const handleStartTuning = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementStartTuning(id);

    if (res.status) {
      await refreshData();
    } else {
      if (res.errorCode === "PMSST01") {
        statementActionButtonGroupRef.current.handleModalActionClick("FORCE_START_TUNING");
      }
    }
  };

  const handleForceStartTuning = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementStartTuning(id, true);

    if (res.status) await refreshData();
  };

  const handleExtendTuning = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementExtendTuning(id);

    if (res) await refreshData();
  };

  const handleMerge = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementMerge(id);

    if (res) await refreshData();
  };

  const handleMarkAsGood = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementMarkAsGood(id);

    if (res) await refreshData();
  };

  const handleMarkAsError = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementMarkAsError(id);

    if (res) await refreshData();
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementSendEmail(id);

    if (res) await refreshData();
  };

  const handleSendCallback = async (e) => {
    e.preventDefault();
    const res = await postMergeStatementCallbackPartner(id);

    if (res) await refreshData();
  };

  const tuningStatus = (status) => {
    let bg = "";
    let color = "";

    switch (status) {
      case "marked_as_good":
        bg = "success";
        break;
      case "marked_as_error":
        bg = "danger";
        break;
      case "started":
        bg = "warning";
        color = "text-dark";
        break;
      default:
        bg = "secondary";
    }

    return (
      <h5>
        <Badge bg={bg} className={color} pill>
          {transformSnakeToText(status)}
        </Badge>
      </h5>
    );
  };

  const typeStatus = (type) => {
    let bg = "";
    let color = "";

    switch (type) {
      case "sme":
        bg = "success";
        break;
      default:
        bg = "danger";
    }

    return (
      <h5>
        <Badge bg={bg} className={color} pill>
          {transformSnakeToText(type)}
        </Badge>
      </h5>
    );
  };

  const sourceBadge = (type) => {
    let bg = "";
    let color = "";

    switch (type) {
      case "api":
        bg = "info";
        break;
      case "dashboard":
        bg = "primary";
        break;
      default:
        bg = "danger";
    }

    return (
      <h5>
        <Badge bg={bg} className={color} pill>
          {transformSnakeToText(type)}
        </Badge>
      </h5>
    );
  };

  const otherStatuses = (status) => {
    let bg = "";
    let color = "";

    switch (status) {
      case "completed":
        bg = "success";
        break;
      case "failed":
        bg = "danger";
        break;
      case "error":
        bg = "danger";
        break;
      case "in_progress":
        bg = "warning";
        color = "text-dark";
        break;
      default:
        bg = "secondary";
    }

    return (
      <h5>
        <Badge bg={bg} className={color} pill>
          {transformSnakeToText(status)}
        </Badge>
      </h5>
    );
  };

  const getDetail = useCallback(async () => {
    await getMergeStatement(id);
  }, []);

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <div className="info">
        <Row className="mb-3">
          <Col>
            <h4 className="mb-4">Detail Information</h4>
          </Col>
          <Col xs="10" className="d-flex gap-3 justify-content-end align-items-center">
            <TimerClock />
            <MergeStatementActionButtonGroup
              ref={statementActionButtonGroupRef}
              onStartTuning={handleStartTuning}
              onExtendTuning={handleExtendTuning}
              onMerge={handleMerge}
              onMarkAsGood={handleMarkAsGood}
              onMarkAsError={handleMarkAsError}
              onForceStartTuning={handleForceStartTuning}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>File Name</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.fileName || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Partner</p>
          </Col>
          <Col xs={4}>{loading ? <Skeleton /> : <p>{mergeStatement?.partnerName || "-"}</p>}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Created At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.createdAt || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Fine Tuning By</p>
          </Col>
          <Col xs={4}>{loading ? <Skeleton /> : <p>{mergeStatement?.fineTuningBy?.name || "-"}</p>}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Updated At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.updatedAt || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Fine Tuning Status</p>
          </Col>
          <Col xs={4}>
            {loading ? (
              <Skeleton />
            ) : (
              <span className="text-capitalize">
                {mergeStatement?.fineTuningStatus ? tuningStatus(mergeStatement.fineTuningStatus) : "-"}
              </span>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Processed At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.processedAt || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Fine Tuning Started At</p>
          </Col>
          <Col xs={4}>{loading ? <Skeleton /> : <p>{mergeStatement?.fineTuningStartedAt || "-"}</p>}</Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Ready At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.readyAt || "-"}</p>}
          </Col>
          <Col xs={2}>
            <p>Fine Tuning Completed At</p>
          </Col>
          <Col xs={4} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.fineTuningCompletedAt || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 6, span: 2 }}>
            <p>Type</p>
          </Col>
          <Col xs={4}>
            {loading ? (
              <Skeleton />
            ) : (
              <span className="text-capitalize">{mergeStatement?.type ? typeStatus(mergeStatement.type) : "-"}</span>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 6, span: 2 }}>
            <p>Source</p>
          </Col>
          <Col xs={4}>
            {loading ? (
              <Skeleton />
            ) : (
              <span className="text-capitalize">
                {mergeStatement?.source ? sourceBadge(mergeStatement.source) : "-"}
              </span>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 6, span: 2 }}>
            <p>Status</p>
          </Col>
          <Col xs={4}>
            {loading ? (
              <Skeleton />
            ) : (
              <span className="text-capitalize">
                {mergeStatement?.status ? otherStatuses(mergeStatement.status) : "-"}
              </span>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 6, span: 2 }}>
            <p>Callback Status</p>
          </Col>
          <Col xs={4}>
            {loading ? (
              <Skeleton />
            ) : (
              <span className="text-capitalize">
                {mergeStatement?.callbackStatus ? otherStatuses(mergeStatement.callbackStatus) : "-"}
              </span>
            )}
          </Col>
        </Row>
      </div>
      <hr />

      <div className="info">
        <h4 className="mb-4">Filters</h4>

        <Row className="mb-2">
          <Col xs={2}>
            <p>Window Dressing</p>
          </Col>
          <Col xs={10} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.windowDressingFilters || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Supplier</p>
          </Col>
          <Col xs={10} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.supplierFilters || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Buyer</p>
          </Col>
          <Col xs={10} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.buyerFilters || "-"}</p>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Categorization</p>
          </Col>
          <Col xs={10} className="text-left">
            {loading ? <Skeleton /> : <p>{mergeStatement?.categorizations || "-"}</p>}
          </Col>
        </Row>
      </div>
      <hr />

      <div className="processed-files">
        <h4 className="mb-4">Processed Files</h4>

        <div className="d-flex align-items-center gap-4 flex-wrap">
          {loading
            ? Array.from(Array(3).keys()).map((_, index) => (
                <div className="file d-flex flex-column justify-content-center align-items-center rounded" key={index}>
                  <FontAwesomeIcon icon={faFile} size="3x" className="mb-2 text-muted" />
                </div>
              ))
            : mergeStatement?.processedFiles.map((file, index) => (
                <Row key={index}>
                  <Col xs="auto" className="text-center">
                    <a
                      href={file.htmlUrl}
                      target="_blank"
                      className="file d-flex flex-column justify-content-center align-items-center rounded"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFile} size="3x" className="mb-2 text-muted" />
                      <p className="text-uppercase fw-bold">{file.name}</p>
                    </a>
                    <a href={file.url} target="_blank" rel="noreferrer">
                      Download
                    </a>
                  </Col>
                </Row>
              ))}
        </div>

        {mergeStatement?.processedAt && (
          <div className="mt-5">
            <ButtonGroup>
              <StatementSendEmailButton handleCallback={(e) => handleSendEmail(e)} variant="outline-primary" />
              {mergeStatement?.status !== "failed" && mergeStatement?.source === "api" ? (
                <StatementSendCallbackButton
                  handleCallback={(e) => handleSendCallback(e)}
                  variant="outline-info"
                  status={mergeStatement?.status}
                />
              ) : (
                <></>
              )}
            </ButtonGroup>
          </div>
        )}
      </div>
    </>
  );
};

export default Info;
