import { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Form, Button, Spinner } from "react-bootstrap";
import { getAdminQRCode2FA, putAdmin2FASetting } from "utils/api";
import { useAuth } from "contexts/auth";
import { useNotification } from "contexts/notification";
import CustomInput from "components/Common/CustomInput";
import ModalBox from "components/Common/ModalBox";
import Guidelines2FA from "./Guidelines";

const TwoFactorAuthentication = () => {
  const { user, getDetail, logout } = useAuth();
  const [selectedStatus2FA, setSelectedStatus2FA] = useState("");
  const [loadingQRCode, setLoadingQRCode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [twoFactorAuthData, setTwoFactorAuthData] = useState({
    qrcode: "",
    secret: "",
    token_code: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const { pushNotification } = useNotification();
  const { Select } = Form;

  const onChangeSelectedStatus2FA = (e) => {
    if (e.target.value) {
      setSelectedStatus2FA(e.target.value);
    }
  };

  const handleGetQRCode = async () => {
    try {
      setLoadingQRCode(true);

      const res = await getAdminQRCode2FA();

      if (res.status === 200) {
        setTwoFactorAuthData({
          ...res.data.data,
        });
      }
    } catch (e) {
      pushNotification("error", "Failed to change password", e);
    } finally {
      setLoadingQRCode(false);
    }
  };

  const handleUpdate2FASetting = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      const res = await putAdmin2FASetting({
        status: selectedStatus2FA,
        secret: twoFactorAuthData.secret,
        token_code: twoFactorAuthData.token_code,
      });
      await getDetail();
      setOpenModal(true);
      setTwoFactorAuthData({
        qrcode: "",
        secret: "",
        token_code: "",
      });
      pushNotification("success", res.data?.message);
    } catch (e) {
      pushNotification("error", "Failed to update 2FA Setting", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (user.two_factor_authentication) {
      setSelectedStatus2FA(user.two_factor_authentication);
    }
  }, [user.two_factor_authentication]);

  useEffect(() => {
    if (selectedStatus2FA === "active" && !user.secret) {
      handleGetQRCode();
    }
  }, [selectedStatus2FA]);

  return (
    <div className="mt-5">
      <Card>
        <CardBody>
          <Row>
            <Col>
              <form onSubmit={(e) => handleUpdate2FASetting(e)}>
                <label>Status Two-Factor Authentication</label>
                <Select
                  value={selectedStatus2FA}
                  disabled={loadingQRCode || isSubmitting}
                  onChange={(e) => onChangeSelectedStatus2FA(e)}
                  className="my-3"
                >
                  <option value="" hidden>
                    Choose Status
                  </option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Select>
                {selectedStatus2FA === "active" && !user.secret ? (
                  <>
                    <Guidelines2FA qrcode={twoFactorAuthData.qrcode} loadingQRCode={loadingQRCode} />
                    <Row>
                      <Col className="px-4">
                        <CustomInput
                          name="token_code"
                          type="text"
                          required
                          value={twoFactorAuthData.token_code}
                          onChange={(e) =>
                            setTwoFactorAuthData({
                              ...twoFactorAuthData,
                              token_code: e.target.value,
                            })
                          }
                          disabled={isSubmitting}
                          inputClassName="my-3"
                          onlyAcceptNumbers
                        />
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!twoFactorAuthData.token_code || isSubmitting}
                        >
                          {isSubmitting && (
                            <span className="me-2">
                              <Spinner size="sm" animation="border" variant="white" />
                            </span>
                          )}
                          <span>Verify 2FA Code</span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col>
                      <Button variant="primary" type="submit" disabled={isSubmitting} className="my-3 me-3">
                        {isSubmitting && (
                          <span className="me-2">
                            <Spinner size="sm" animation="border" variant="white" />
                          </span>
                        )}
                        <span>Update 2FA Setting</span>
                      </Button>
                    </Col>
                  </Row>
                )}
              </form>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalBox
        show={openModal}
        title={"Success Update 2FA Setting"}
        body={
          "The Two-Factor Authentication (2FA) status has been updated. For security reasons, please click the button below to log out. You will need to log in again to apply the changes."
        }
        isShowNegativeClick={false}
        showCloseButtonModal={false}
        labelPositiveClick={"Logout"}
        variantPositiveClick={"outline-danger"}
        onPositiveClick={() => handleLogout()}
      />
    </div>
  );
};

export default TwoFactorAuthentication;
