import ModalBox from "components/Common/ModalBox";
import { useState, useEffect } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import PaginationTable from "./PaginationTable";

const modalDefault = {
  show: false,
  title: "",
  body: "",
  positiveCallback: null,
  negativeCallback: null,
};

const StatementImportModal = (props) => {
  const { show, handleConfirmationCallback, handleCancellationCallback, pages, transactions } = props;
  const [modal, setModal] = useState(modalDefault);

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      size: "xl",
      show: show,
      title: "Import Statement",
    }));
  }, [show]);

  return (
    <ModalBox
      size={modal.size}
      show={modal.show}
      title={modal.title}
      body={
        <>
          <p>Are you sure you want to Import this statement?</p>
          <Alert variant="warning" className="mt-2">
            <strong>Warning!</strong> If you have done HITL on the transactions, the changes will be gone and
            overridden.
          </Alert>
          <Tabs fill mountOnEnter>
            <Tab eventKey="pagse" title={`Pages (${pages?.length})`}>
              <div
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                }}
              >
                <PaginationTable
                  data={pages}
                  itemsPerPage={100}
                  headerRow={
                    <tr>
                      <th style={{ width: "1%", minWidth: "30px" }}>#</th>
                      <th style={{ width: "50px" }}>Page</th>
                      <th>Bank</th>
                      <th style={{ minWidth: "140px" }}>Account Name</th>
                      <th style={{ minWidth: "140px" }}>Account Number</th>
                      <th style={{ width: "75px" }}>Period</th>
                    </tr>
                  }
                  recordRowMap={(item, index, { offsetNumber }) => (
                    <tr key={index}>
                      <td className="text-end pe-2">{offsetNumber + index + 1}</td>
                      <td style={{ textAlign: "right" }}>{item.page_number}</td>
                      <td>{item.bank_name}</td>
                      <td>{item.bank_account_name}</td>
                      <td>{item.bank_account_number}</td>
                      <td style={{ textAlign: "right" }}>{item.period}</td>
                    </tr>
                  )}
                />
              </div>
            </Tab>
            <Tab eventKey="transactions" title={`Transactions (${transactions?.length})`}>
              <div
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                }}
              >
                <PaginationTable
                  data={transactions}
                  itemsPerPage={100}
                  headerRow={
                    <tr>
                      <th style={{ width: "1%", minWidth: "30px" }}>#</th>
                      <th style={{ width: "50px" }}>Page</th>
                      <th style={{ width: "145px" }}>Timestamp</th>
                      <th style={{ width: "50%" }}>Description</th>
                      <th>Amount</th>
                      <th>Ending Balance</th>
                      <th style={{ width: "7.5%" }}>Position</th>
                    </tr>
                  }
                  recordRowMap={(item, index, { offsetNumber }) => (
                    <tr key={index}>
                      <td className="text-end pe-2">{offsetNumber + index + 1}</td>
                      <td style={{ textAlign: "right" }}>{item.page_number}</td>
                      <td>{item.timestamp}</td>
                      <td>{item.description}</td>
                      <td style={{ textAlign: "right" }}>{item.amount}</td>
                      <td style={{ textAlign: "right" }}>{item.ending_balance}</td>
                      <td>{item.position}</td>
                    </tr>
                  )}
                />
              </div>
            </Tab>
          </Tabs>
        </>
      }
      onPositiveClick={handleConfirmationCallback}
      onNegativeClick={() => {
        handleCancellationCallback();
        handleCloseModal();
      }}
    />
  );
};

export default StatementImportModal;
