import Breadcrumb from "components/Common/Breadcrumb";
import AutocompleteInput from "components/Common/Autocomplete/AutocompleteInput";
import Footer from "components/Common/Footer";
import NavigationBar from "components/Common/NavigationBar";
import { useStatement } from "contexts/statement";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { transformCameltoSnakeObject } from "utils/helper";

const StatementPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const { id } = useParams();
  const {
    getStatementPage,
    statementPage,
    loading,
    postStatementPage,
    parserModuleNameList,
    statementModuleNameList,
    getParserStatementModuleNameList,
  } = useStatement();
  const { Control: Input } = Form;

  // Gets the query params.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const copyFrom = queryParams.get("copyFrom");

  const breadcrumbItems = [
    { link: `/merge-statement/${statementPage?.mergeUuid}`, text: `Merge Statement` },
    { link: `/statement/${id}`, text: `Statement` },
    { link: `/statement/${id}/new-page?copyFrom=${copyFrom}`, text: `New Page` },
  ];

  const getDetail = useCallback(async (currentId) => {
    await getStatementPage(currentId);
  }, []);

  const handleInputChange = (e, type) => {
    setFormData({
      ...formData,
      [type]: ["engineParserModuleName", "engineStatementModuleName"].includes(type) ? e : e.target.value,
    });
  };

  const handleInfoSave = async () => {
    const res = await postStatementPage(id, transformCameltoSnakeObject(formData));

    if (res?.success && res?.newId) navigate(`/statement-page/${res.newId}`);
  };

  const handleFilterParserStatementModuleName = async (search, type) => {
    await getParserStatementModuleNameList({ search, type });
  };

  useEffect(() => {
    getDetail(copyFrom);
  }, [copyFrom]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      bankName: statementPage?.bankName,
      bankAccountNumber: statementPage?.bankAccountNumber,
      bankAccountName: statementPage?.bankAccountName,
      companyName: statementPage?.companyName,
      period: statementPage?.period,
      engineParserModuleName: statementPage?.engineParserModuleName,
      engineStatementModuleName: statementPage?.engineStatementModuleName,
    }));
  }, [statementPage]);

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <div className="info">
          <h4 className="mb-4">New Statement Page</h4>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Page Number</p>
            </Col>
            <Col xs={4} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.pageNumber || ""}
                  onChange={(e) => handleInputChange(e, "pageNumber")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankName || ""}
                  onChange={(e) => handleInputChange(e, "bankName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Account Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankAccountName || ""}
                  onChange={(e) => handleInputChange(e, "bankAccountName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Bank Account Number</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.bankAccountNumber || ""}
                  onChange={(e) => handleInputChange(e, "bankAccountNumber")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Company Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input
                  type="text"
                  value={formData?.companyName || ""}
                  onChange={(e) => handleInputChange(e, "companyName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Period</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <Input type="text" value={formData?.period || ""} onChange={(e) => handleInputChange(e, "period")} />
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mb-2">
            <Col xs={2}>
              <p>Parser Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <AutocompleteInput
                  defaultValue={formData?.engineParserModuleName}
                  listData={parserModuleNameList.data}
                  getData={handleFilterParserStatementModuleName}
                  type="parser"
                  propertyName="moduleName"
                  displayPropertyName="moduleName"
                  inputPlaceholder="Search for parser module name..."
                  onChangeValue={(e) => handleInputChange(e, "engineParserModuleName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={2}>
              <p>Statement Name</p>
            </Col>
            <Col xs={6} className="text-left">
              {loading ? (
                <Skeleton />
              ) : (
                <AutocompleteInput
                  defaultValue={formData?.engineStatementModuleName}
                  listData={statementModuleNameList.data}
                  getData={handleFilterParserStatementModuleName}
                  type="statement"
                  propertyName="moduleName"
                  displayPropertyName="displayStatementModuleName"
                  inputPlaceholder="Search for statement module name..."
                  onChangeValue={(e) => handleInputChange(e, "engineStatementModuleName")}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={8} className="d-flex justify-content-end">
              <Button variant="outline-primary" onClick={handleInfoSave} disabled={loading}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default StatementPage;
