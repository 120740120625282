import { useState } from "react";
import { useParams } from "react-router";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useLocation, createSearchParams, useNavigate } from "react-router-dom";

import MergeSlikDetailInfo from "../../../components/Slik/MergeSlikDetailInfo";
import MergeSlikDetailSlikContainer from "../../../components/Slik/MergeSlikDetailSlikContainer";
import MergeSlikDetailPartnerLog from "../../../components/Slik/MergeSlikDetailPartnerLog";
import NavigationBar from "components/Common/NavigationBar";

import Footer from "components/Common/Footer";
import Breadcrumb from "components/Common/Breadcrumb";

import "./index.css";

const MergeSlikDetail = () => {
  const { id } = useParams();
  const breadcrumbItems = [{ link: `/merge-slik/${id}`, text: `Merge Slik` }];

  // Sets default active tab on first load.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get("tab");

  // Tabs state.
  const [key, setKey] = useState(queryTab || "tab-slik");

  // Tabs event.
  const handleTabSelect = (key) => {
    setKey(key);

    // Updates tab for browser history.
    const params = new URLSearchParams(location.search);
    params.set("tab", key);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <MergeSlikDetailInfo />

        <Tabs activeKey={key} onSelect={handleTabSelect} className="my-3" fill mountOnEnter>
          <Tab eventKey="tab-slik" title={<h4>Slik</h4>}>
            <MergeSlikDetailSlikContainer />
          </Tab>
          <Tab eventKey="tab-partner-log" title={<h4>Partner Log</h4>}>
            <MergeSlikDetailPartnerLog />
          </Tab>
        </Tabs>
      </Container>
      <Footer />
    </>
  );
};

export default MergeSlikDetail;
