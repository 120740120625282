import MergeStatementDashboardTable from "components/Statement/MergeStatementDashboardTable";
import MergeStatementDashboardTableInfo from "components/Statement/MergeStatementDashboardTableInfo";

const Dashboard = ({ onPageChange, params }) => {
  return (
    <>
      <MergeStatementDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
      <MergeStatementDashboardTable />
      <MergeStatementDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
    </>
  );
};

export default Dashboard;
