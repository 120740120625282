import { Container } from "react-bootstrap";
import NavigationBar from "components/Common/NavigationBar";
import FineTuningLogDashboardData from "components/FineTuningLog/FineTuningLogDashboardData";
import FineTuningLogDashboardMeta from "components/FineTuningLog/FineTuningLogDashboardMeta";
import Footer from "components/Common/Footer";

const Dashboard = () => {
  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <h2 className="mb-4">Fine Tuning Log</h2>
        <div className="mb-4">
          <FineTuningLogDashboardMeta />
        </div>
        <FineTuningLogDashboardData />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
