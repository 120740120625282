import { BrowserRouter, Navigate, Route, Routes, Outlet } from "react-router-dom";

import MergeStatementDashboard from "pages/Statement/MergeStatementDashboard";
import MergeStatementDetail from "pages/Statement/MergeStatementDetail";
import StatementDetail from "pages/Statement/StatementDetail";
import StatementDS from "pages/Statement/StatementDS/index";
import StatementPage from "pages/Statement/StatementPage/index";
import StatementPageNew from "pages/Statement/StatementPage/new";

import FineTuningLogDashboard from "pages/FineTuningLogDashboard";
import PartnerLogDashboard from "pages/PartnerLogDashboard";
import MergeSlikDashboard from "pages/Slik/MergeSlikDashboard";
import MergeSlikDetail from "pages/Slik/MergeSlikDetail";
import MergePefindoDashboard from "pages/Pefindo/MergePefindoDashboard";
import MergePefindoDetail from "pages/Pefindo/MergePefindoDetail";
import SandboxDashboard from "pages/SandboxDashboard";

import DashboardProfile from "pages/Profile";
import AccountInfo from "pages/Profile/AccountInfo";
import ChangePassword from "pages/Profile/ChangePassword";
import TwoFactorAuthentication from "pages/Profile/TwoFactorAuthentication";

import Error404 from "pages/Error404";
import Login from "pages/Login";

import ProtectedRoute from "components/Global/Route/Protected";
import Notification from "components/Global/Notification";

import AuthProvider from "contexts/auth";
import NotificationProvider from "contexts/notification";
import FineTuningLogProvider from "contexts/finetuning_log";
import PartnerLogProvider from "contexts/partner_log";
import StatementProvider from "contexts/statement";
import SlikProvider from "contexts/slik";
import PefindoProvider from "contexts/pefindo";
import SandboxProvider from "contexts/sandbox";
import SocketProvider from "contexts/socket";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

import "./index.css";

const App = () => {
  return (
    <NotificationProvider>
      <AuthProvider>
        <Notification />
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/" element={<Navigate to="statement-dashboard" />} />
            <Route path="404" element={<Error404 />} />

            <Route element={<ProtectedRoute />}>
              <Route
                element={
                  <SocketProvider>
                    <Outlet />
                  </SocketProvider>
                }
              >
                <Route
                  element={
                    <StatementProvider>
                      <Outlet />
                    </StatementProvider>
                  }
                >
                  <Route path="statement-dashboard" element={<MergeStatementDashboard />} />
                  <Route path="merge-statement/:id" element={<MergeStatementDetail />} />
                  <Route path="statement/:id/new-page" element={<StatementPageNew />} />
                  <Route path="statement/:id" element={<StatementDetail />} />
                  <Route path="statement-page/:id" element={<StatementPage />} />
                  <Route path="statement-ds/:id" element={<StatementDS />} />
                </Route>
                <Route
                  element={
                    <SlikProvider>
                      <Outlet />
                    </SlikProvider>
                  }
                >
                  <Route path="slik-dashboard" element={<MergeSlikDashboard />} />
                  <Route path="merge-slik/:id" element={<MergeSlikDetail />} />
                </Route>
                <Route
                  element={
                    <PefindoProvider>
                      <Outlet />
                    </PefindoProvider>
                  }
                >
                  <Route path="pefindo-dashboard" element={<MergePefindoDashboard />} />
                  <Route path="merge-pefindo/:id" element={<MergePefindoDetail />} />
                </Route>
                <Route
                  element={
                    <PartnerLogProvider>
                      <Outlet />
                    </PartnerLogProvider>
                  }
                >
                  <Route path="partner-log-dashboard" element={<PartnerLogDashboard />} />
                </Route>
                <Route
                  element={
                    <FineTuningLogProvider>
                      <Outlet />
                    </FineTuningLogProvider>
                  }
                >
                  <Route path="finetuning-log-dashboard" element={<FineTuningLogDashboard />} />
                </Route>
                <Route
                  element={
                    <SandboxProvider>
                      <Outlet />
                    </SandboxProvider>
                  }
                >
                  <Route path="sandbox" element={<SandboxDashboard />} />
                </Route>
                <Route path="profile" element={<DashboardProfile />}>
                  <Route index element={<Navigate to="account-info" />} />
                  <Route path="account-info" element={<AccountInfo />} />
                  <Route path="change-password" element={<ChangePassword />} />
                  <Route path="two-factor-authentication" element={<TwoFactorAuthentication />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="404" />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </NotificationProvider>
  );
};

export default App;
