import { useCallback, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Breadcrumb from "components/Common/Breadcrumb";
import Footer from "components/Common/Footer";
import NavigationBar from "components/Common/NavigationBar";
import StatementDetailInfoContainer from "components/Statement/StatementDetailInfoContainer";
import StatementDetailDSListContainer from "components/Statement/StatementDetailDSListContainer";
import StatementDetailPageListContainer from "components/Statement/StatementDetailPageListContainer";

import { useStatement } from "contexts/statement";

const StatementDetail = () => {
  const { id } = useParams();
  const { getStatement, statement } = useStatement();

  const breadcrumbItems = [
    { link: `/merge-statement/${statement?.mergeUuid}`, text: `Merge Statement` },
    { link: `/statement/${id}`, text: `Statement` },
  ];

  const getDetail = useCallback(async () => {
    await getStatement(id);
  }, []);

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <StatementDetailInfoContainer />
        <hr />
        <StatementDetailDSListContainer />
        <hr />
        <StatementDetailPageListContainer />
      </Container>
      <Footer />
    </>
  );
};

export default StatementDetail;
