import ModalBox from "components/Common/ModalBox";
import { useStatement } from "contexts/statement";
import { useState } from "react";
import { Button } from "react-bootstrap";

const modalDefault = {
  show: false,
  title: "",
  body: "",
  positiveCallback: null,
  negativeCallback: null,
};

const StatementSendEmailButton = (props) => {
  const { handleCallback, variant = "primary" } = props;
  const [modal, setModal] = useState(modalDefault);
  const { loading } = useStatement();

  const handleActionClick = () => {
    setModal((prev) => ({
      ...prev,
      show: true,
      title: "Send Email Result",
      positiveCallback: handleCallback,
    }));
  };

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <>
      <ModalBox
        show={modal.show}
        title={modal.title}
        body={
          <>
            <p>Are you sure you want to Send Email the result of this statement?</p>
          </>
        }
        onPositiveClick={modal.positiveCallback}
        onNegativeClick={handleCloseModal}
      />
      <Button variant={variant} onClick={handleActionClick} disabled={loading}>
        Send Email
      </Button>
    </>
  );
};

export default StatementSendEmailButton;
