import { useState } from "react";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalBox from "components/Common/ModalBox";
import { useStatement } from "contexts/statement";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const tableHeader = (
  <tr>
    <th>#</th>
    <th>Bank</th>
    <th style={{ minWidth: "140px" }}>Account Name</th>
    <th style={{ minWidth: "140px" }}>Account Number</th>
    <th style={{ minWidth: "140px" }}>Company Name</th>
    <th style={{ width: "75px" }}>Period</th>
    <th style={{ minWidth: "140px" }}>Parser Name</th>
    <th style={{ minWidth: "140px" }}>Statement Name</th>
    <th style={{ width: "100px" }}>Created At</th>
    <th style={{ width: "85px" }}>Action</th>
  </tr>
);

const modalDefault = {
  show: false,
  title: "",
  body: "",
  positiveCallback: null,
  negativeCallback: null,
};

const StatementDetailPageTable = (props) => {
  const { onStatementPageDeleted } = props;
  const { statementPageList, loading } = useStatement();
  const [modal, setModal] = useState(modalDefault);

  const handleActionClick = (type, item) => {
    let tempCallback = null;
    let title = "";
    let body = "";

    switch (type) {
      case "DELETE":
        tempCallback = (e) => onStatementPageDeleted(e, item);
        title = "Delete Statement Page";
        body = `Are you sure you want to delete Statement Page ${item?.pageNumber}?`;
        break;
      default:
        break;
    }

    setModal((prev) => ({
      ...prev,
      show: true,
      title,
      body,
      positiveCallback: tempCallback,
    }));
  };

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <>
      <Table responsive bordered striped hover size="sm" className="mb-4">
        <thead>{tableHeader}</thead>
        <tbody>
          {loading
            ? Array.from(Array(5).keys()).map((val1) => (
                <tr key={val1}>
                  {Array.from(Array(10).keys()).map((val2) => (
                    <td key={`${val1}${val2}`}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))
            : statementPageList?.data.map((item) => (
                <tr key={item.uuid}>
                  <td>{item.pageNumber}</td>
                  <td>{item.bankName}</td>
                  <td>{item.bankAccountName}</td>
                  <td>{item.bankAccountNumber}</td>
                  <td>{item.companyName}</td>
                  <td>{item.period}</td>
                  <td>{item.engineParserModuleName}</td>
                  <td>{item.engineStatementModuleName}</td>
                  <td>{item.createdAt}</td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Open</Tooltip>}>
                      <Button size="sm" variant="link" className="px-1" as={Link} to={`/statement-page/${item.uuid}`}>
                        <FontAwesomeIcon icon={faEye} className="text-primary pointer-event" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        className="ms-3"
                        onClick={() => handleActionClick("DELETE", item)}
                        disabled={loading}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      <ModalBox
        show={modal.show}
        title={modal.title}
        body={modal.body}
        onPositiveClick={modal.positiveCallback}
        onNegativeClick={handleCloseModal}
      />
    </>
  );
};

export default StatementDetailPageTable;
