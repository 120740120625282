import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useLocation, createSearchParams, useNavigate } from "react-router-dom";

import Footer from "components/Common/Footer";
import Breadcrumb from "components/Common/Breadcrumb";
import NavigationBar from "components/Common/NavigationBar";
import MergeStatementDetailInfo from "components/Statement/MergeStatementDetailInfo";
import MergeStatementDetailStatementList from "components/Statement/MergeStatementDetailStatementList";
import MergeStatementDetailPartnerLog from "components/Statement/MergeStatementDetailPartnerLog";
import MergeStatementDetailFineTuningLog from "components/Statement/MergeStatementDetailFineTuningLog";
import { getMergeStatementDebug } from "utils/api";

import "./index.css";
import Skeleton from "react-loading-skeleton";

const Debug = () => {
  const { id } = useParams();
  const [debugData, setDebugData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const res = await getMergeStatementDebug(id);
        setDebugData(res.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);
  return (
    <>
      {loading ? <Skeleton height={250} /> : ""}
      {!loading && debugData && <pre style={{ minHeight: "250px" }}>{JSON.stringify(debugData, null, 3)}</pre>}
    </>
  );
};

const MergeStatementDetail = () => {
  const { id } = useParams();
  const breadcrumbItems = [{ link: `/merge-statement/${id}`, text: `Merge Statement` }];

  // Sets default active tab on first load.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get("tab");

  // Tabs state.
  const [key, setKey] = useState(queryTab || "tab-statement");

  // Tabs event.
  const handleTabSelect = (key) => {
    setKey(key);

    // Updates tab for browser history.
    const params = new URLSearchParams(location.search);
    params.set("tab", key);
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options);
  };

  return (
    <>
      <NavigationBar />
      <Breadcrumb items={breadcrumbItems} />
      <Container style={{ marginBottom: "50px" }}>
        <MergeStatementDetailInfo />

        <Tabs activeKey={key} onSelect={handleTabSelect} className="my-3" fill mountOnEnter>
          <Tab eventKey="tab-statement" title={<h4>Statement</h4>}>
            <MergeStatementDetailStatementList />
          </Tab>
          <Tab eventKey="tab-partner-log" title={<h4>Partner Log</h4>}>
            <MergeStatementDetailPartnerLog />
          </Tab>
          <Tab eventKey="tab-finetuning-log" title={<h4>Fine Tuning Log</h4>}>
            <MergeStatementDetailFineTuningLog />
          </Tab>
          <Tab eventKey="tab-debug" title={<h4>Debug</h4>}>
            <Debug />
          </Tab>
        </Tabs>
      </Container>
      <Footer />
    </>
  );
};

export default MergeStatementDetail;
