import { useCallback, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import NavigationBar from "components/Common/NavigationBar";
import MergePefindoDashboardData from "components/Pefindo/MergePefindoDashboardData";
import MergePefindoDashboardMeta from "components/Pefindo/MergePefindoDashboardMeta";
import Footer from "components/Common/Footer";
import AutocompleteInput from "components/Common/Autocomplete/AutocompleteInput";
import { usePefindo } from "contexts/pefindo";
import { transformSnakeToCamelInArray } from "utils/helper";
import { getAutoCompletePartnerList as getAutoCompletePartnerListApi } from "utils/api";

const defaultParams = {
  page: 1,
  limit: 25,
  partner_id: "",
  partner_company: "",
};

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("");
  const [trackState, setTrackState] = useState("");

  // Gets default value from query params.
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
    defaultParams.page = page;
  }

  const selectedPartnerUUID = queryParams.get("partner");
  const selectedPartnerCompany = queryParams.get("company");

  defaultParams.partner_company = selectedPartnerCompany;
  defaultParams.partner_id = selectedPartnerUUID;

  const [params, setParams] = useState(defaultParams);
  const {
    isAutoUpdateList,
    handleAutoUpdateList,
    getMergePefindoList,
    getAutoCompletePartnerList,
    autoCompletePartnerList,
    autoCompletePartnerCompanyList,
    getAutoCompletePartnerCompanyList,
  } = usePefindo();

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getList = useCallback(
    async (newParams = {}, displayFilter = "") => {
      const tempParams = params;

      const mergedParams = { ...params, ...newParams };
      if (
        newParams.partner_id !== params.partner_id ||
        newParams.partner_company !== params.partner_company ||
        newParams.page !== params.page
      ) {
        setParams(mergedParams);
        setSelectedFilter(displayFilter || "");
        setSelectedCompanyFilter(newParams.partner_company || "");

        await getMergePefindoList({
          ...tempParams,
          ...newParams,
        });
      }
    },
    [params],
  );

  const getSelectedPartnerByFilters = useCallback(
    async (uuid = null, companyName, queryPage) => {
      let partnerData;
      if (uuid) {
        const response = await getAutoCompletePartnerListApi({ uuid });

        if (response.data.data && response.data.data.length === 1) {
          const data = transformSnakeToCamelInArray(response.data.data);
          partnerData = data[0];
        }
      }

      getList(
        {
          partner_id: partnerData?.id || "",
          partner_company: companyName,
          page: queryPage || 1,
        },
        partnerData?.displayFilter,
      );
      setTrackState("filtered");
      setIsCollapsed(false);
    },
    [params],
  );

  const handleFilterAutocompletePartnerList = async (search) => {
    await getAutoCompletePartnerList({ search });
  };

  const handleFilterAutocompletePartnerCompanyList = async (search) => {
    await getAutoCompletePartnerCompanyList({ search });
  };

  const handleInputChange = (value, type) => {
    const searchParams = new URLSearchParams(location.search);
    if (type === "partner_id") {
      searchParams.set("partner", value.uuid);
      setSelectedFilter(value.displayFilter);
    }
    if (type === "partner_company") {
      searchParams.set("company", value.companyName);
      setSelectedCompanyFilter(value.companyName);
    }
    searchParams.set("page", 1);
    setParams({
      ...params,
      page: 1,
    });
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(searchParams)}`,
    };
    setTrackState("filtered");
    navigate(options);
  };

  const onPageChange = (text) => {
    setTrackState(text);
  };

  const resetFilter = () => {
    navigate("/pefindo-dashboard");
    getList({
      partner_id: "",
      page: 1,
      partner_company: "",
    });
    defaultParams.page = 1;
    defaultParams.partner_id = "";
    defaultParams.partner_company = "";
    setSelectedFilter("");
    setTrackState("");
    setSelectedCompanyFilter("");
    setIsCollapsed(true);
  };

  useEffect(() => {
    if (!selectedPartnerUUID && !selectedPartnerCompany && !trackState) {
      const queryParams = new URLSearchParams(location.search);
      const newPage = queryParams.get("page");
      if (!Number.isNaN(Number.parseInt(newPage, 10)) && newPage > 0) {
        defaultParams.page = newPage;
      }
      getList({
        partner_id: "",
        page: newPage || 1,
        partner_company: "",
      });
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newPage = queryParams.get("page");
    if (!Number.isNaN(Number.parseInt(newPage, 10)) && newPage > 0) {
      defaultParams.page = newPage;
    }
    if (selectedPartnerUUID || selectedPartnerCompany) {
      getSelectedPartnerByFilters(selectedPartnerUUID, selectedPartnerCompany, newPage);
    } else if (trackState) {
      getList({
        partner_id: "",
        page: newPage || 1,
        partner_company: "",
      });
    }
  }, [location.search]);

  return (
    <>
      <NavigationBar />
      <Container style={{ marginBottom: "50px" }}>
        <div className="d-flex align-items-center mb-4">
          <h2>Pefindo</h2>
          <div className="ms-auto d-flex align-items-center">
            <div>
              <Form>
                <Form.Check
                  id="auto-update"
                  type="checkbox"
                  label="Auto Refresh New Submission"
                  checked={isAutoUpdateList}
                  onChange={handleAutoUpdateList}
                />
              </Form>
            </div>
            <button onClick={handleCollapseToggle} className="btn btn-outline-primary text-sm ms-3">
              Filter <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} className="ms-2" />
            </button>
          </div>
        </div>
        <div className={`${isCollapsed ? "d-none" : "d-block"}`}>
          <div className="d-flex">
            <label style={{ width: "90px" }} className="my-auto">
              Partner
            </label>
            <AutocompleteInput
              key={selectedFilter}
              defaultValue={selectedFilter}
              listData={autoCompletePartnerList.data}
              getData={handleFilterAutocompletePartnerList}
              type="partner"
              propertyName="displayFilter"
              displayPropertyName="displayFilter"
              inputPlaceholder="Filter by partner name / partner email / partner company"
              onChangeValue={(value) => handleInputChange(value, "partner_id")}
              getAllPropertyInSelectedData
            />
          </div>
          <div className="d-flex my-3">
            <label style={{ width: "90px" }} className="my-auto">
              Company
            </label>
            <AutocompleteInput
              key={selectedCompanyFilter}
              defaultValue={selectedCompanyFilter}
              listData={autoCompletePartnerCompanyList.data}
              getData={handleFilterAutocompletePartnerCompanyList}
              type="partner"
              propertyName="companyName"
              displayPropertyName="companyName"
              inputPlaceholder="Filter by company name"
              onChangeValue={(value) => handleInputChange(value, "partner_company")}
              getAllPropertyInSelectedData
            />
          </div>
          <div className="text-end">
            <button onClick={() => resetFilter()} className="btn btn-outline-primary w-25">
              Reset Filter
            </button>
          </div>
        </div>
        <div className="mb-4">
          <MergePefindoDashboardMeta />
        </div>
        <MergePefindoDashboardData params={params} onPageChange={onPageChange} />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
