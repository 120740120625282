import { createContext, useContext } from "react";
// import { getFineTuningLogList, getFineTuningLogChart } from "utils/api";
// import {
//   transformSnakeToCamelInArray,
//   chartColors,
//   transformChartToMultiDatasets,
//   transformChartToStackGroupDataset,
// } from "utils/helper";
// import { useNotification } from "contexts/notification";

// const responseDefault = {
//   type: null,
//   status: false,
//   message: null,
//   data: null,
// };

export const SandboxContext = createContext(null);

export const useSandbox = () => {
  const ctx = useContext(SandboxContext);

  if (!ctx) {
    throw new Error("useSandbox must be used within the SandboxProvider");
  }

  return ctx;
};

const SandboxProvider = ({ children }) => {
  return (
    <SandboxContext.Provider
      value={{
        loading: true,
        chartLoading: true,
        response: true,
      }}
    >
      {children}
    </SandboxContext.Provider>
  );
};

export default SandboxProvider;
