import { useEffect, useState, useCallback } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "contexts/auth";
import NavigationBar from "components/Common/NavigationBar";
import Footer from "components/Common/Footer";

const DashboardProfile = () => {
  const [activeKey, setActiveKey] = useState("");
  const { user, getDetail } = useAuth();
  const location = useLocation();
  const getDetailAuth = useCallback(async () => {
    await getDetail();
  }, []);

  const initializeKey = () => {
    let activeKey = "";
    if (location.pathname.includes("change-password")) {
      activeKey = "change-password";
    } else if (location.pathname.includes("account-info")) {
      activeKey = "account-info";
    } else if (location.pathname.includes("two-factor-authentication")) {
      activeKey = "two-factor-authentication";
    }

    setActiveKey(activeKey);
  };

  useEffect(() => {
    if (user.uuid) {
      getDetailAuth();
    }
  }, [user.uuid]);

  useEffect(() => {
    initializeKey();
  }, [location.pathname]);

  return (
    <>
      <NavigationBar />
      {user.name ? (
        <Container style={{ marginBottom: "50px" }}>
          <h2 className="mb-4">My Profile</h2>
          <Tab.Container activeKey={activeKey}>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link as={NavLink} to="account-info" eventKey="admin-info">
                  Account Information
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="change-password" eventKey="change-password">
                  Change Password
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="two-factor-authentication" eventKey="two-factor-authentication">
                  2FA
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane>
                <Outlet />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <Outlet />
        </Container>
      ) : null}
      <Footer />
    </>
  );
};

export default DashboardProfile;
