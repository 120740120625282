import CryptoJS from "crypto-js";

const MARGIN_CALL = "5ae62d36c8484f14bc6105950cd687a5";

export const encryptWithAES = (text) => {
  return CryptoJS.AES.encrypt(text, MARGIN_CALL).toString();
};

export const decryptWithAES = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, MARGIN_CALL);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
