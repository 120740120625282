import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SandboxTabSearching from "components/Sandbox/SandboxTabSearching";
const Dashboard = () => {
  const [key, setKey] = useState("tab-test-searching");
  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
      <Tab eventKey="tab-test-searching" title={<h4>Searching</h4>}>
        <SandboxTabSearching />
      </Tab>
    </Tabs>
  );
};

export default Dashboard;
