import axios from "plugins/axios";

// Version 1.0.0.2
// Auth
export const postPreLogin = async (payload) => {
  const result = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/admin/pre-login`, payload);
  return result;
};

export const postLogin = async (payload) => {
  const result = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/admin/login`, payload);
  return result;
};

export const postVerify = async (payload) => {
  const result = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/admin/verify`, payload);
  return result;
};

export const postLogout = async () => {
  const result = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/admin/logout`);
  return result;
};

export const putChangePassword = async (payload) => {
  const result = await axios.put(`${process.env.REACT_APP_AUTH_API_URL}/admin/change-password`, payload);
  return result;
};

export const getAdminDetail = async () => {
  const result = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/admin/detail`);
  return result;
};

export const getAdminQRCode2FA = async () => {
  const result = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/admin/detail/qrcode`);
  return result;
};

export const putAdmin2FASetting = async (payload) => {
  const result = await axios.put(`${process.env.REACT_APP_AUTH_API_URL}/admin/detail/two-factor-auth`, payload);
  return result;
};

// statement
export const getMergeStatementList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-statement/list`, { params });
  return result;
};

export const getMergeStatement = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-statement/detail/${id}`);
  return result;
};

export const getMergeStatementPartnerLogList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-statement/partner-log-list/${id}`);
  return result;
};

export const getMergeStatementFineTuningLogList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-statement/finetuning-log-list/${id}`);
  return result;
};

export const getMergeStatementDebug = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-statement/debug/${id}`);
  return result;
};

export const getStatementList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/list`, { params });
  return result;
};

export const getStatementChart = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/chart`, { params });
  return result;
};

export const postMergeStatementStartTuning = async (id, payload) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/start-tuning/${id}`, payload);
  return result;
};

export const postMergeStatementExtendTuning = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/extend-tuning/${id}`);
  return result;
};

export const postMergeStatementMerge = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/merge/${id}`);
  return result;
};

export const postMergeStatementMarkAsGood = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/mark-as-good/${id}`);
  return result;
};

export const postMergeStatementMarkAsError = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/mark-as-error/${id}`);
  return result;
};

export const postMergeStatementSendEmail = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/send-email/${id}`);
  return result;
};

export const postMergeStatementCallbackPartner = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/merge-statement/partner-callback/${id}`);
  return result;
};

export const getStatementPageList = async (params, id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/list-page/${id}`, { params });
  return result;
};

export const getStatementPageExport = async (id, params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/export-page/${id}`, { params });
  return result;
};

export const getStatement = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/detail/${id}`);
  return result;
};

export const getParserStatementModuleNameList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/parser-statement/list`, { params });
  return result;
};

export const getAutoCompletePartnerList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/autocomplete/partner/list`, { params });
  return result;
};

export const getAutoCompletePartnerCompanyList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/autocomplete/partner/company-list`, { params });
  return result;
};

// statement page
export const getStatementPage = async (id, params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/detail-page/${id}`, { params });
  return result;
};

export const postStatementPage = async (id, payload) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/statement/new-page/${id}`, payload);
  return result;
};

export const postStatementImport = async (id, payload) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/statement/import/${id}`, payload);
  return result;
};

export const putStatementSavePage = async (id, payload) => {
  const result = await axios.put(`${process.env.REACT_APP_BO_API_URL}/statement/save-page/${id}`, payload);
  return result;
};

export const deleteStatementPage = async (id) => {
  const result = await axios.delete(`${process.env.REACT_APP_BO_API_URL}/statement/delete-page/${id}`);
  return result;
};

export const getStatementTransactionList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/list-transaction/${id}`);
  return result;
};

export const getStatementTransactionExport = async (id, params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/export-transaction/${id}`, { params });
  return result;
};

export const putStatementSaveTransaction = async (id, payload) => {
  const result = await axios.put(`${process.env.REACT_APP_BO_API_URL}/statement/save-transaction/${id}`, payload);
  return result;
};

export const postStatementParse = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/statement/parse/${id}`);
  return result;
};

export const postStatementProcess = async (id) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/statement/process/${id}`);
  return result;
};

// statement ds
export const getStatementDS = async (id, params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/detail-ds/${id}`, { params });
  return result;
};

export const putStatementSaveDS = async (id, payload) => {
  const result = await axios.put(`${process.env.REACT_APP_BO_API_URL}/statement/save-ds/${id}`, payload);
  return result;
};

export const getStatementDSFraudList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/statement/list-ds-fraud/${id}`);
  return result;
};

export const putStatementSaveDSFraud = async (id, payload) => {
  const result = await axios.put(`${process.env.REACT_APP_BO_API_URL}/statement/save-ds-fraud/${id}`, payload);
  return result;
};

// slik
export const getMergeSlikList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-slik/list`, { params });
  return result;
};

export const getMergeSlik = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-slik/detail/${id}`);
  return result;
};

export const getMergeSlikPartnerLogList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-slik/partner-log-list/${id}`);
  return result;
};

export const getSlikList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/slik/list`, { params });
  return result;
};

export const getSlikChart = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/slik/chart`, { params });
  return result;
};

// pefindo
export const getMergePefindoList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-pefindo/list`, { params });
  return result;
};

export const getMergePefindo = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-pefindo/detail/${id}`);
  return result;
};

export const getMergePefindoPartnerLogList = async (id) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/merge-pefindo/partner-log-list/${id}`);
  return result;
};

export const getPefindoList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/pefindo/list`, { params });
  return result;
};

export const getPefindoChart = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/pefindo/chart`, { params });
  return result;
};

// log
export const getPartnerLogList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/partner-log/list`, { params });
  return result;
};

export const getPartnerLogChart = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/partner-log/chart`, { params });
  return result;
};

export const getFineTuningLogList = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/finetuning-log/list`, { params });
  return result;
};

export const getFineTuningLogChart = async (params) => {
  const result = await axios.get(`${process.env.REACT_APP_BO_API_URL}/finetuning-log/chart`, { params });
  return result;
};

// tool
export const postToolStringRegex = async (payload) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/tool/string/regex`, payload);
  return result;
};

export const postToolStringSimilarity = async (payload) => {
  const result = await axios.post(`${process.env.REACT_APP_BO_API_URL}/tool/string/similarity`, payload);
  return result;
};
