import MergeSlikDashboardTable from "components/Slik/MergeSlikDashboardTable";
import MergeSlikDashboardTableInfo from "components/Slik/MergeSlikDashboardTableInfo";

const Dashboard = ({ onPageChange, params }) => {
  return (
    <>
      <MergeSlikDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
      <MergeSlikDashboardTable />
      <MergeSlikDashboardTableInfo onPageChange={onPageChange} limit={params.limit} />
    </>
  );
};

export default Dashboard;
