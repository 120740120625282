import { useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";

import { useNotification } from "contexts/notification";
import { postToolStringRegex, postToolStringSimilarity } from "utils/api";

const { Control: Input, Check: CheckBox } = Form;

const SearchingSection = ({ title, postFunction, initialDescription }) => {
  const { pushNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const [filter, setFilter] = useState("PT Sukses Jaya Makmur");
  const [slicing, setSlicing] = useState(true);
  const [output, setOutput] = useState({});

  const handleRegexTest = async () => {
    try {
      setLoading(true);
      setOutput({});
      const res = await postFunction({ description, filter, slicing });
      setOutput(res.data.data);
    } catch (e) {
      pushNotification("error", "Test Failed", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="mb-4 shadow">
      <Card.Header>
        <h5>{title}</h5>
      </Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Description</p>
          </Col>
          <Col xs={10} className="text-left">
            <Input as="textarea" value={description} rows={5} onChange={(e) => setDescription(e.target.value)} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={2}>
            <p>Filter</p>
          </Col>
          <Col xs={10} className="text-left">
            <Input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 2, span: 10 }} className="text-left">
            <CheckBox
              id={"check-" + title}
              type="checkbox"
              label="Slicing"
              checked={slicing}
              onChange={(e) => setSlicing(e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={2}>
            <p>Output</p>
          </Col>
          <Col xs={10} className="text-left">
            <Input as="textarea" value={JSON.stringify(output, null, 2)} rows={7} readOnly />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={{ offset: 2, span: 10 }} className="d-grid">
            <Button variant="outline-secondary" onClick={() => handleRegexTest()} disabled={loading}>
              Test
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const TabSearching = () => {
  return (
    <>
      <SearchingSection
        title="Pattern"
        postFunction={postToolStringRegex}
        initialDescription="TRF DR 129 0090111001119 Sukses Jaya VIA INTERNET BANKING IBB17.62089"
      />
      <SearchingSection
        title="Similarity"
        postFunction={postToolStringSimilarity}
        initialDescription="TRF DR 129 0090111001119 Sukses J4ya VIA INTERNET BANKING IBB17.62089"
      />
    </>
  );
};

export default TabSearching;
